<template>
  <v-app>
    <div>
      <div class="card card-custom">
        <div class="card-body p-0">
          <div
              class="wizard wizard-2"
              id="kt_wizard_v2"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
          >
            <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12">
                  <h3>
                    Attendance summary
                  </h3>
                  <router-link :to="{name:'dashboard'}">
                    Dashboard
                  </router-link>
                  \
                  Attendance
                </div>
              </div>
              <div class="row">
                <div>
                  <v-tabs v-model="tab">
                    <v-tab v-if="checkIsAccessible('attendance' ,'show')">
                      Summary
                    </v-tab>
                    <v-tab v-if="checkIsAccessible('attendance' ,'create')">
                      Add new
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">

                    <v-tab-item v-if="checkIsAccessible('attendance' ,'show')">
                      <div class="card-body p-0">
                        <v-form class="form" id="kt_password_change_form">
                          <div class="form-group row">
                            <div class="col-lg-2">
                              <v-select
                                  :items="levels"
                                  class="form-control"
                                  v-model="search.level_id"
                                  label="Level"
                                  item-value="id"
                                  item-text="title"
                                  outlined
                                  @change="getPrograms"
                                  dense>
                              </v-select>
                            </div>


                            <div class="col-lg-2">
                              <v-select
                                  outlined
                                  dense
                                  class="form-control"
                                  v-model="selectedProgram"
                                  :items="programs"
                                  label="Programs"
                                  item-value="id"
                                  item-text="title"
                                  return-object
                                  @change="getGrades"
                              >
                              </v-select>

                            </div>

                            <div class="col-lg-2">
                              <v-select
                                  :items="academicYears"
                                  class="form-control"
                                  v-model="search.academic_year_id"
                                  label="Academic year"
                                  item-value="id"
                                  item-text="year"
                                  outlined
                                  dense>
                              </v-select>
                            </div>
                            <div class="col-lg-2">
                              <v-select
                                  outlined
                                  dense
                                  class="form-control"
                                  v-model="search.grade_id"
                                  :items="gradesLevels"
                                  label="Semester/year"
                                  item-value="id"
                                  item-text="title"
                                  @change="getAcademicClasses"
                              ></v-select>

                            </div>
                            <div class="col-lg-2">
                              <v-select
                                  outlined
                                  dense
                                  class="form-control"
                                  v-model="search.academic_class_id"
                                  :items="academic_classes"
                                  @change="getTimeTable"
                                  label="Class name"
                                  item-value="id"
                                  item-text="title"
                              ></v-select>

                            </div>
                            <div class="col-lg-2" v-if="attendance_type == 'subject'">
                              <v-select
                                  outlined
                                  dense
                                  class="form-control"
                                  v-model="search.time_table_id"
                                  :items="academicClassTimetables"
                                  label="Timetable name"
                                  @change="getAcademicTimetablePeriod()"
                                  item-value="id"
                                  item-text="title"
                              ></v-select>

                            </div>
                            <div class="col-lg-2" v-if="attendance_type == 'subject'">
                              <v-select
                                  outlined
                                  dense
                                  class="form-control"
                                  v-model="search.timetable_period_id"
                                  :items="academicClassTimetablePeriods"
                                  label="Period name"
                                  item-value="id"
                                  item-text="subject_name"
                              ></v-select>


                            </div>
                            <div class="col-lg-2">
                              <v-select
                                  outlined
                                  dense
                                  class="form-control"
                                  v-model="search.month"
                                  :items="monthNames"
                                  label="Month"
                                  item-value="value"
                                  item-text="name"
                              ></v-select>


                            </div>

                            <div class="col-lg-2">
                              <button
                                  @click.prevent="getReport"
                                  type="submit"
                                  class="form-control form-control-lg btn btn-primary font-size-lg"
                                  name="search"
                                  ref="search"
                              >Search
                              </button>
                            </div>
                            <div class="col-lg-2">
                              <button
                                  class="form-control form-control-lg btn btn-secondary font-size-lg"
                                  @click.prevent="resetFilter"

                              >Reset
                              </button>
                            </div>


                          </div>
                        </v-form>
                      </div>
                      <v-card v-if="attendanceReports.report">
                        <v-card-title>
                          Attendance report <span class="ml-2"
                                                  v-if="attendanceReports"> {{ ` of ${attendanceReports.month_name}, ${attendanceReports.year} - ${attendanceReports.subject}  ` }}</span>
                        </v-card-title>
                        <v-card-text>
                          <table class="table-bordered" style="border-color: #000;">
                            <tr>
                              <th class="text-center">{{ attendanceReports.month_name }}</th>
                              <th class="text-center" colspan="4"> Total</th>
                              <th class="text-center" v-for="day in attendanceReports.end_to" :key="day">
                                {{
                                  `${days[new
                                  Date(search.year, search.month - 1,
                                      day).getDay()]}`
                                }}
                              </th>
                            </tr>
                            <tr>
                              <th class="text-center">Name</th>
                              <th class="text-center"> Present</th>
                              <th class="text-center"> Absent</th>
                              <th class="text-center"> Late</th>
                              <th class="text-center"> Half Day</th>

                              <th style="width: 50px" class="text-center" v-for="day in attendanceReports.end_to">
                                {{ day }}
                              </th>
                            </tr>
                            <tr v-for="(student,index) in attendanceReports.report">
                              <td style="width: 330px" class=" pl-1">
                                {{ student.full_name }}
                              </td>
                              <td class="text-center" style="width: 80px">
                                {{ student.total_present }}
                              </td>
                              <td class="text-center" style="width: 80px">
                                {{ student.total_absent }}
                              </td>
                              <td class="text-center" style="width: 80px">
                                {{ student.total_late }}
                              </td>
                              <td class="text-center" style="width: 80px">
                                {{ student.total_half_day }}
                              </td>
                              <td v-for="(day, key) in student.attendance_all" @click="status(index, day)"
                                  :style="{backgroundColor: getColor(day[key+1])}" class="text-center text-white">
                                {{ getAttendanceDay(day[key + 1]) }}
                              </td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item v-if="checkIsAccessible('attendance' ,'create')"

                    >
                      <div class="card card-custom">
                        <div class="card-body p-0">
                          <div class="row">
                            <v-row class="text-center">
                              <v-col cols="12" class="text-center">

                                <div class="card-body">
                                  <v-form class="form" id="kt_password_change_form">
                                    <div class="form-group row">


                                      <div class="col-lg-2">
                                        <v-select
                                            :items="levels"
                                            class="form-control"
                                            v-model="search.level_id"
                                            label="Level"
                                            item-value="id"
                                            item-text="title"
                                            outlined
                                            @change="getPrograms"
                                            dense>
                                        </v-select>
                                      </div>


                                      <div class="col-lg-2">
                                        <v-select
                                            outlined
                                            dense
                                            class="form-control"
                                            v-model="selectedProgram"
                                            :items="programs"
                                            label="Programs"
                                            item-value="id"
                                            item-text="title"
                                            return-object
                                            @change="getGrades"
                                        >
                                        </v-select>

                                      </div>

                                      <div class="col-lg-2">
                                        <v-select
                                            :items="academicYears"
                                            class="form-control"
                                            v-model="search.academic_year_id"
                                            label="Academic year"
                                            item-value="id"
                                            item-text="year"
                                            outlined
                                            dense>
                                        </v-select>
                                      </div>
                                      <div class="col-lg-2">
                                        <v-select
                                            outlined
                                            dense
                                            class="form-control"
                                            v-model="search.grade_id"
                                            :items="gradesLevels"
                                            label="Semester/year"
                                            item-value="id"
                                            item-text="title"
                                            @change="getAcademicClasses"
                                        ></v-select>

                                      </div>
                                      <div class="col-lg-2">
                                        <v-select
                                            outlined
                                            dense
                                            class="form-control"
                                            v-model="search.academic_class_id"
                                            :items="academic_classes"
                                            @change="getTimeTable"
                                            label="Class name"
                                            item-value="id"
                                            item-text="title"
                                        ></v-select>

                                      </div>
                                      <div class="col-lg-2" v-if="attendance_type == 'subject'">
                                        <v-select
                                            outlined
                                            dense
                                            class="form-control"
                                            v-model="search.time_table_id"
                                            :items="academicClassTimetables"
                                            label="Timetable name"
                                            @change="getAcademicTimetablePeriod()"
                                            item-value="id"
                                            item-text="title"
                                        ></v-select>

                                      </div>

                                      <div class="col-lg-2" v-if="attendance_type == 'subject'">
                                        <v-select
                                            outlined
                                            dense
                                            class="form-control"
                                            v-model="search.timetable_period_id"
                                            :items="academicClassTimetablePeriods"
                                            label="Period name"
                                            item-value="id"
                                            item-text="subject_name"
                                        ></v-select>

                                      </div>
                                      <div class="col-lg-2">
                                        <v-menu
                                            ref="menu1"
                                            v-model="menu1"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="search.date"
                                                label="Date"
                                                persistent-hint
                                                outlined
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-date-picker
                                              v-model="search.date"
                                              no-title
                                              @input="menu1 = false"
                                          ></v-date-picker>
                                        </v-menu>

                                      </div>

                                      <div class="col-lg-2">
                                        <button
                                            @click.prevent="getStudentAttendance"
                                            type="submit"
                                            :disabled="!checkSearchStatus"
                                            class="form-control form-control-lg btn btn-primary font-size-lg"
                                            name="search"
                                            ref="search"
                                        >Search
                                        </button>
                                      </div>
                                      <div class="col-lg-2">
                                        <button
                                            class="form-control form-control-lg btn btn-secondary font-size-lg"
                                            @click.prevent="resetFilter"

                                        >Reset
                                        </button>
                                      </div>


                                    </div>
                                  </v-form>
                                </div>

                              </v-col>
                              <v-col cols="12" v-if="selected.length>0">
                                <v-card flat color="grey lighten-4">
                                  <v-toolbar flat color="grey lighten-4">
                                    <v-toolbar-title>Total selected: {{ selected.length }}
                                    </v-toolbar-title>
                                    <v-toolbar-title class="ml-5"> Actions :</v-toolbar-title>
                                    <!--                                            <v-btn depressed @click="addStudents" >-->
                                    <!--                                                <v-icon>assignment_ind</v-icon>-->
                                    <!--                                                Add-->

                                    <!--                                            </v-btn>-->
                                    <v-radio-group row v-model="attendance"
                                                   @change="setAllAttendance()">

                                      <v-radio value="p">
                                        <template v-slot:label>
                                          Present
                                        </template>
                                      </v-radio>
                                      <v-radio value="a">
                                        <template v-slot:label>
                                          Absent
                                        </template>
                                      </v-radio>
                                      <v-radio value="pl">
                                        <template v-slot:label>
                                          Late
                                        </template>
                                      </v-radio>
                                      <v-radio value="ph">
                                        <template v-slot:label>
                                          Half Day
                                        </template>
                                      </v-radio>
                                    </v-radio-group>

                                    <v-radio-group
                                        v-if="attendance_type == 'day'"
                                        row v-model="day_attendance_type"
                                        @change="setAllAttendanceCheckType()">

                                      <v-radio value="in">
                                        <template v-slot:label>
                                          Check In
                                        </template>
                                      </v-radio>
                                      <v-radio value="out">
                                        <template v-slot:label>
                                          Check Out
                                        </template>
                                      </v-radio>

                                    </v-radio-group>

                                    <v-btn @click="saveAttendance()" depressed
                                           class="text-white btn btn-primary">
                                      SAVE
                                    </v-btn>
                                    <v-btn @click="getStudentAttendance()" depressed
                                           class="ml-3 text-gray btn btn-standard">
                                      RESET
                                    </v-btn>


                                    <!--                                            <v-btn icon>-->
                                    <!--                                                <v-icon>mdi-heart</v-icon>-->
                                    <!--                                            </v-btn>-->

                                    <!--                                            <v-btn icon>-->
                                    <!--                                                <v-icon>mdi-dots-vertical</v-icon>-->
                                    <!--                                            </v-btn>-->

                                  </v-toolbar>
                                </v-card>

                              </v-col>
                            </v-row>

                            <div class="col-12" v-if="users.length">
                              <table class="table table-stripe">
                                <thead>
                                <th>
                                  <v-checkbox @change="selectAll()" v-model="checkAll" outlined dense
                                              label="Full Name"></v-checkbox>
                                </th>
                                <th>Image</th>
                                <th>Symbol number</th>
                                <th>Attendance</th>
                                <th>Remark</th>
                                </thead>
                                <tbody>
                                <tr v-for="(user, index) of users" :key="index">
                                  <td>
                                    <v-checkbox v-model="selected" :value="user.student_id" outlined
                                                dense
                                                v-bind:label="(user.full_name)?user.full_name:null"></v-checkbox>
                                  </td>
                                  <td>
                                    <div class="symbol-label">
                                      <img v-if="user.photo"
                                           :src="user.image_path.thumb"
                                           class="cursor-pointer" alt=""
                                           @click="changeImage(user)"
                                           style="height: 30px;">
                                      <span v-else class="symbol symbol-35 symbol-light-success">
                                                    <span
                                                        class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                                                        @click="changeImage(user)"> {{
                                                        (user.full_name) ? user.full_name.charAt(0) : null
                                                      }} </span>
                                                </span>
                                    </div>
                                  </td>

                                  <td>{{ user.setting ? user.setting.symbol_no : "N/A" }}
                                  </td>
                                  <td>
                                    <v-radio-group row v-model="user.attendance"
                                                   @change="setAttendance(index)">

                                      <v-radio value="p">
                                        <template v-slot:label>
                                          Present
                                        </template>
                                      </v-radio>
                                      <v-radio value="a">
                                        <template v-slot:label>
                                          Absent
                                        </template>
                                      </v-radio>
                                      <v-radio value="pl">
                                        <template v-slot:label>
                                          Late
                                        </template>
                                      </v-radio>
                                      <v-radio value="ph">
                                        <template v-slot:label>
                                          Half day
                                        </template>
                                      </v-radio>


                                    </v-radio-group>
                                  </td>

                                  <td>
                                    <v-text-field
                                        dense
                                        v-model="user.remark"
                                        outlined
                                        label="Remark"
                                    ></v-text-field>
                                  </td>


                                </tr>
                                </tbody>
                              </table>

                            </div>
                          </div>
                        </div>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
  </v-app>

</template>
<script>
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import {required} from "vuelidate/lib/validators";
import AttendanceService from "@/core/services/attendance/AttendanceService";
import AcademicCLassTimetableService from "@/core/services/academicClassTimetable/AcademicCLassTimetableService";
import AcademicCLassTimetablePeriodService
  from "@/core/services/academicClassTimetablePeriod/AcademicCLassTimetablePeriodService";

const academicCLassTimetablePeriod = new AcademicCLassTimetablePeriodService();
const academicClassTimetable = new AcademicCLassTimetableService();
const attendance = new AttendanceService();
const userService = new UserService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService();

import {AttendanceMixin} from "@/mixins/AttendanceMixin";

export default {
  name: "users",
  mixins: [AttendanceMixin],
  components: {},
  validations: {},
  data() {
    var d = new Date(),
        month = d.getMonth(),
        year = d.getYear();
    return {
      month: month,
      year: year,
      no_of_days: new Date(year, month + 1, 0),
      tab: null,
      import_excel: false,
      checkAll: false,
      isLoading: false,
      dialog: false,
      menu: false,
      menu1: false,
      day_attendance_type: null,

      excel_file: null,
      attendance: null,
      isBusy: false,
      uploadDialog: false,
      students: [],
      studentCards: [],
      academicClassTimetables: [],
      academicClassTimetablePeriods: [],

      date: new Date().toISOString().substr(0, 10),
      search: {
        // date: null,
        // academic_class_id: null,
        // time_table_id: null,
        // timetable_period_id: null,

        academic_class_id: null,
        day_attendance: false,
        time_table_id: "",
        timetable_period_id: "",
        level_id: null,
        program_id: null,
        academic_year_id: null,
        year: 2021,
        month: 5,
        grade_id: null
      },
      attendanceReports: [],
      users: [],
      selected: [],
      studentList: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      levels: [],
      levelId: null,
      attendance_type: "subject",
      selectedProgram: null,
      page: null,
      total: null,
      perPage: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    checkSearchStatus() {
      if (this.search.level_id && this.selectedProgram && this.search.academic_year_id && this.search.academic_class_id
          && this.search.grade_id && this.search.date)
        return true;
      else
        return false;
    }
  },
  mounted() {
    this.getLevels();
    this.getAcademicYears();
  },
  methods: {
    status(index, day) {
      this.users[index][day] = !this.users[index][day]
      this.$set(this.users, index, this.users[index])
    },

    formatDate() {
      return this.class.start_date.split('/').join('-');
    },
    getReport() {
      let month = this.search.month;
      this.search.month = this.search.month.toLocaleString('en-US', {//this is the function that formats the numbers
        minimumIntegerDigits: 2, //change this to your minimum length
        useGrouping: false
      });
      attendance.getReport(this.search).then(response => {
        this.attendanceReports = response.data;
      });
      this.search.month = month;
    },

    resetFilter() {
      this.search = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        academic_year_id: '',
        level_id: '',
        program_id: '',
        grade_id: '',
        class_id: '',
        type: 'student',
        limit: 80
      }
      this.users = [];
      // this.getUsers();


    },

    addStudents() {
      this.selected.forEach((student) => {
        if (this.studentList.indexOf(student) === -1) {
          this.studentList.push(student);
        }
      })

    },
    removeStudentFromList(index) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.studentList.splice(index, 1);
          }
        }
      });

    },

    getAcademicTimetablePeriod() {
      academicCLassTimetablePeriod.getClassSubjectsById(this.search.time_table_id).then((resources) => {
        this.academicClassTimetablePeriods = resources.data.academicClassTimetablePeriod;
      }).catch(err => {
        // console.log(err)
      })
    },
    getStudentAttendance() {
      this.attendance_type == 'day' ? this.search.day_attendance = true : false;
      attendance.getStudentAttendance(this.search).then(response => {
        this.users = response.data
      })
    },


    getTimeTable() {
      academicClassTimetable.getByClass(this.search.academic_class_id).then((response) => {
        this.academicClassTimetables.push(response.data.academicClassTimetable);
        // this.getAcademicTimetablePeriod();
      })
    },
    selectAll() {
      if (!this.checkAll) {
        this.selected = [];
      } else {
        this.users.forEach(user => {
          this.selected.push(user.student_id);
        })

      }
    },


    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        if (this.search.id && this.search.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.search.level_id).then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;

      });
    },
    getSelected() {
      let selected = []
      this.selected.forEach(studnet_id => {
        this.users.forEach(user => {
          if (studnet_id == user.student_id) {
            selected.push(user)
          }
        })
      })
      return selected;
    },

    saveAttendance() {
      let selected = this.getSelected()
      attendance.store(selected).then((response) => {
        this.$snotify.success('Information updated');
        this.getStudentAttendance()
        this.selected = []
        this.checkAll = false
        this.attendance = null
      }).catch(err => {
        // console.log(err)
        // this.$snotify.error(err);
      })
    },
    getGrades() {
      this.selectedProgram.attendance_type == 'day' ? this.attendance_type = 'day' : this.attendance_type = 'subject';
      gradeLevelService.getByProgram(this.selectedProgram.id).then(response => {
        this.gradesLevels = response.data;

      });
    },
    getAcademicClasses() {
      academicClassService.getByProgramLevel(this.search.academic_year_id, this.selectedProgram.id, this.search.grade_id).then(response => {
        this.academic_classes = response.data;
      });
    },


  }
};
</script>

<style>
.form-control {
  border: none !important;
}

.attenedance table {
  border-collapse: collapse;
}

.attenedance table, th, td {
  /*border: 1px solid black;*/
}

.table th, .table thead th, .table td {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}

.table {
  background: #fff;
}

.v-input--selection-controls__input + .v-label {
  margin-bottom: 0 !important;
}

.v-toolbar__title {
  padding-left: 10px;
}

.table .v-input .v-messages,
.v-input--radio-group .v-messages {
  display: none;
}

.v-toolbar__content .v-input--radio-group--row {
  margin-left: 10px;
}

.v-toolbar__content .v-input--radio-group--row + .v-input--radio-group--row {
  margin-left: 0px;
}

.v-toolbar__content .v-input--radio-group--row .v-input__slot {
  margin-bottom: 0;
}

.v-input__slot {
  background: transparent !important;

}

.table-bordered, .table-bordered th, .table-bordered td {
  border-color: #000 !important;
}
</style>
